// extracted by mini-css-extract-plugin
export var customText = "p_qf d_ds d_cq d_cd";
export var videoIframeStyle = "p_pF d_d3 d_t d_F d_bw d_bZ d_P";
export var customImageWrapper = "p_qg d_cq d_cd d_X";
export var customRow = "p_qh d_bB d_bc";
export var quoteWrapper = "p_gM d_bz d_bM d_cq d_cd";
export var quoteBar = "p_pK d_F";
export var masonryImageWrapper = "p_pR";
export var title = "p_qj";
export var Title3Small = "p_qk t_qk t_q9 t_rj";
export var Title3Normal = "p_ql t_ql t_q9 t_rk";
export var Title3Large = "p_qm t_qm t_q9 t_rl";