// extracted by mini-css-extract-plugin
export var lbContainerOuter = "x_ty";
export var lbContainerInner = "x_tz";
export var movingForwards = "x_tB";
export var movingForwardsOther = "x_tC";
export var movingBackwards = "x_tD";
export var movingBackwardsOther = "x_tF";
export var lbImage = "x_tG";
export var lbOtherImage = "x_tH";
export var prevButton = "x_tJ";
export var nextButton = "x_tK";
export var closing = "x_tL";
export var appear = "x_tM";