// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pn";
export var articleText = "m_pp d_ds d_cq";
export var header = "m_pq d_X d_t";
export var headerImageWrapper = "m_pr d_bb d_Y d_6 d_5 d_2 d_7 d_bh d_bP";
export var headerGradient = "m_ps d_bb d_Y d_6 d_5 d_2 d_7";
export var headerGradientOverlay = "m_pt d_bb d_Y d_6 d_5 d_2 d_7";
export var headerContentWrapper = "m_pv d_bx d_bM d_bB d_bG d_bj d_X";
export var headerContentWrapperAlt = "m_pw d_bx d_bM d_bB d_bG d_bj d_X";
export var contentWrapper = "m_mw d_t";
export var dateTag = "m_px d_cc d_cp d_dn d_t";
export var icon1 = "m_py d_cM";
export var icon2 = "m_pz d_cM d_cG";
export var tagBtn = "m_pB d_bZ d_cB d_dm d_dy";
export var headerText = "m_pC d_t d_cc d_cq d_bT";
export var center = "m_pD d_dt";
export var videoIframeStyle = "m_pF d_d3 d_t d_F d_bw d_bZ d_P";
export var articleImageWrapper = "m_pG d_cq d_X";
export var articleImageWrapperIcon = "m_pH d_cq d_X";
export var articleRow = "m_pJ d_bB d_bc";
export var quoteWrapper = "m_gM d_bz d_bM d_cq";
export var quoteBar = "m_pK d_F";
export var quoteText = "m_pL";
export var authorBox = "m_pM d_t";
export var authorRow = "m_pN d_bB d_bT d_cw";
export var separator = "m_pP d_t";
export var line = "m_fh d_t d_fh d_cW d_cw";
export var authorImage = "m_ll d_b5 d_X d_t d_cr";
export var authorText = "m_pQ d_cf d_cr";
export var masonryImageWrapper = "m_pR";
export var bottomSeparator = "m_pS d_t d_cw";
export var linksWrapper = "m_pT d_cf d_ds";
export var comments = "m_pV d_bB d_cw";
export var sharing = "m_pW d_bx d_bB d_bG";
export var shareText = "m_pX d_t d_dt";
export var icon = "m_pY";
export var text = "m_pZ";
export var SubtitleSmall = "m_p0";
export var SubtitleNormal = "m_p1";
export var SubtitleLarge = "m_p2";