// extracted by mini-css-extract-plugin
export var alignLeft = "q_qn d_fm d_bD d_ds";
export var alignCenter = "q_bM d_fn d_bB d_dt";
export var alignRight = "q_qp d_fp d_bF d_dv";
export var FAQMainHeader = "q_kr d_kr d_t d_bw";
export var FAQComponentHeader = "q_kx d_kx d_bv";
export var FAQComponentParagraph = "q_ks d_ks d_cB";
export var FAQComponentsWrapper = "q_kp d_kp d_cl d_cJ d_cP d_c0 d_cV d_dy";
export var FAQComponentsWrapperClean = "q_kq d_kq d_cl d_cJ d_cP d_cY d_dy";
export var accordion__body = "q_ky d_ky d_bw";
export var accordion__bodyHidden = "q_kB d_kB d_bt d_dj";
export var FAQDivider = "q_kn d_kn";
export var accordionWrapper = "q_qq d_cd";
export var accordion__item = "q_qr d_cJ";
export var accordion_arrow = "q_kF d_kF";
export var accordion_arrow_open = "q_kH d_kH";
export var accordion_arrow_2 = "q_kK d_kK";
export var accordion_arrow_2_open = "q_kM d_kM";