// extracted by mini-css-extract-plugin
export var carouselContainer = "w_p5 d_t d_F d_bc d_X";
export var carouselContainerCards = "w_sM w_p5 d_t d_F d_bc d_X";
export var carouselContainerSides = "w_sN d_t d_F d_X";
export var prevCarouselItem = "w_sP d_t d_F d_Y d_h";
export var prevCarouselItemL = "w_sQ w_sP d_t d_F d_Y d_h";
export var moveInFromLeft = "w_sR";
export var prevCarouselItemR = "w_sS w_sP d_t d_F d_Y d_h";
export var moveInFromRight = "w_sT";
export var selectedCarouselItem = "w_sV d_t d_F d_X d_bc";
export var selectedCarouselItemL = "w_sW w_sV d_t d_F d_X d_bc";
export var selectedCarouselItemR = "w_sX w_sV d_t d_F d_X d_bc";
export var nextCarouselItem = "w_sY d_t d_F d_Y d_h";
export var nextCarouselItemL = "w_sZ w_sY d_t d_F d_Y d_h";
export var nextCarouselItemR = "w_s0 w_sY d_t d_F d_Y d_h";
export var arrowContainer = "w_s1 d_F d_Y d_dy d_bj d_2 d_bZ";
export var prevArrowContainer = "w_s2 w_s1 d_F d_Y d_dy d_bj d_2 d_bZ";
export var prevArrowContainerHidden = "w_s3 w_s2 w_s1 d_F d_Y d_dy d_bj d_2 d_bZ d_h";
export var nextArrowContainer = "w_s4 w_s1 d_F d_Y d_dy d_bj d_2 d_bZ";
export var carouselContainerProducts = "w_s5";
export var nextArrowContainerHidden = "w_s6 w_s4 w_s1 d_F d_Y d_dy d_bj d_2 d_bZ d_h";
export var arrow = "w_kD d_Y";
export var prevArrow = "w_s7 w_kD d_Y";
export var nextArrow = "w_s8 w_kD d_Y";
export var carouselIndicatorContainer = "w_s9 d_t d_dt d_bj d_bx d_bB d_bM d_bG d_Y";
export var btnsContainer = "w_tb d_t d_bx d_bC";
export var carouselText = "w_tc d_dn d_bx d_bB d_bM d_bZ d_dy d_cB";
export var carouselTextInactive = "w_td w_tc d_dn d_bx d_bB d_bM d_bZ d_dy d_cB";
export var indicator = "w_tf d_b5";
export var carouselIndicator = "w_tg w_tf d_b5";
export var carouselIndicatorSelected = "w_th w_tf d_b5";
export var arrowsContainerTopRight = "w_tj d_Y d_bj d_bz";
export var arrowsContainerBottomLeft = "w_tk d_Y d_bj d_bz";
export var arrowsContainerSides = "w_tl d_Y d_bj d_bz";
export var smallArrowsBase = "w_tm d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainer = "w_tn w_tm d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerPrev = "w_tp w_tn w_tm d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerSmall = "w_tq w_tm d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerPrevSmall = "w_tr w_tq w_tm d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var icon = "w_pY";
export var iconSmall = "w_ts";
export var multipleWrapper = "w_tt d_bz d_bC d_bc";
export var multipleImage = "w_tv d_bz";
export var sidesPrevContainer = "w_tw w_tn w_tm d_bR d_bx d_bB d_bM d_bZ d_dy d_cB d_fl d_Y d_bj d_cB";
export var sidesNextContainer = "w_tx w_tn w_tm d_bR d_bx d_bB d_bM d_bZ d_dy d_cB d_fl d_Y d_bj d_cB";