// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_qs d_fm d_bD d_ds";
export var alignLeft = "r_qn d_fm d_bD d_ds";
export var alignDiscCenter = "r_qt d_fn d_bB d_dt";
export var alignCenter = "r_bM d_fn d_bB d_dt";
export var alignDiscRight = "r_qv d_fp d_bF d_dv";
export var alignRight = "r_qp d_fp d_bF d_dv";
export var footerContainer = "r_qw d_dT d_bT";
export var footerContainerFull = "r_qx d_dR d_bT";
export var footerHeader = "r_kb d_kb";
export var footerTextWrapper = "r_qy d_t";
export var footerDisclaimerWrapper = "r_kj d_kj d_ch";
export var badgeWrapper = "r_qz d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "r_qB undefined";
export var footerDisclaimerLeft = "r_qC undefined";
export var verticalTop = "r_qD d_bx d_bG d_bL d_bJ";
export var firstWide = "r_qF";
export var disclaimer = "r_qG d_bx d_bG";
export var socialDisclaimer = "r_qH";
export var left = "r_qJ";
export var wide = "r_qK d_cw";
export var right = "r_qL d_bH";
export var line = "r_fh d_fj d_cs";
export var badgeDisclaimer = "r_qM d_bz d_bM d_bG";
export var badgeContainer = "r_qN d_bx d_bF d_bM";
export var badge = "r_qP";
export var padding = "r_qQ d_c5";
export var end = "r_qR d_bF";
export var linkWrapper = "r_qS d_dy";
export var link = "r_mz d_dy";
export var colWrapper = "r_qT d_cv";
export var consent = "r_f d_f d_bz d_bM";
export var media = "r_qV d_bv d_dw";
export var itemRight = "r_qW";
export var itemLeft = "r_qX";
export var itemCenter = "r_qY";
export var exceptionWeight = "r_qZ t_rC";